@charset "UTF-8";
// 構成案
.BlockName {
  @at-root {
    $_localVariable: 10px;

    & {
    }

    .BlockName__element1 {
    }

    .BlockName__element2 {
    }
  }
}

.kv {
  background: #dde9f3 url('../images/key_bg.jpg') no-repeat center top;
  width: 100%;
  padding-top: 10px;
}

@media screen and (min-width: $small-pc) {
  .kv {
    padding-top: 0;
    //background: url("../images/key_bg.jpg") no-repeat;
    height: 333px;
    position: relative;
    &__text01 {
      position: absolute;
      top: 10px;
      left: 18px;
    }
    &__text02 {
      position: absolute;
      top: 20px;
      left: 346px;
    }
    &__text03 {
      position: absolute;
      top: 130px;
      left: 18px;
    }
    &__btn {
      position: absolute;
      top: 15px;
      right: 18px;
      .btn {
        width: 245px;
      }
    }
    &__point {
      position: absolute;
      bottom: 0;
      line-height: 1;
      ul {
        display: flex;
      }
    }
  }
}

.merit {
  margin-bottom: 30px;
  &__title {
    text-align: center;
    //background-color: $simbol-color;

    //background: -moz-linear-gradient(top, #00b3fe 0%, #003399 100%);
    //background: -webkit-linear-gradient(top, #00b3fe 0%,#003399 100%);
    //background: linear-gradient(to bottom, #00b3fe 0%,#003399 100%);

    color: #f00;
    padding: 10px 0;
    margin: 10px 0 5px;
    font-size: 2rem;
    border-bottom: 3px solid #f00;
    //background-color: rgba(255, 0, 0, 0.5);
  }
  &__text {
    font-weight: bold;
  }
}

.merit02 {
  margin: 15px 0;
  &__title {
    font-size: 2rem;
    color: #f00;
    display: inline;
  }
  &__text {
    display: inline;
    font-weight: bold;
    font-size: 2rem;
  }
}

.point {
  margin-bottom: 10px;
  &__item {
    //background-color: #f6f7b9;
    padding: 15px;
    margin-bottom: 10px;
    text-align: center;

    background: rgba(251, 255, 145, 1);
    background: -moz-linear-gradient(
      top,
      rgba(251, 255, 145, 1) 0%,
      rgba(225, 230, 87, 1) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(251, 255, 145, 1)),
      color-stop(100%, rgba(225, 230, 87, 1))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(251, 255, 145, 1) 0%,
      rgba(225, 230, 87, 1) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(251, 255, 145, 1) 0%,
      rgba(225, 230, 87, 1) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(251, 255, 145, 1) 0%,
      rgba(225, 230, 87, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(251, 255, 145, 1) 0%,
      rgba(225, 230, 87, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbff91', endColorstr='#e1e657', GradientType=0 );
  }

  &__text {
    font-weight: bold;
    font-size: 1.7rem;
    color: #f00;
    span {
      font-weight: normal;
    }
  }
}

.feature {
  &__title {
    background-color: #d6e5fa;
  }
  &__list {
    //counter-reset: item;
    //list-style: none;
    //padding-left: 0;
  }
  &__item {
    //text-indent: -3.6rem;
    //padding-left: 3.6rem;
    margin-bottom: 15px;
    font-weight: bold;
    margin-left: 2.5rem;
    &:before {
      //counter-increment: item;
      //content: counter(item);
      //font-weight: bold;
      //font-size: 1.2rem;
      //margin-right: 1rem;
      //background-color: #039;
      //color: #fff;
      //border-radius: 100%;
      //padding: 5px 7px;
    }
  }
  a {
    color: #06c;
  }
}

.use {
  &__title {
    background-color: #fffea6;
  }
}

.flag-list {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
  li {
    list-style: none;
    margin-bottom: 10px;
  }
  &__item {
    height: 33px;
    margin-right: 10px;
    img {
      height: 100%;
      border: 1px solid #ccc;
    }
  }
}

@media screen and (min-width: $small-pc) {
  .flag-list {
    li {
      margin-bottom: 0;
    }
  }
}

.bgBox {
  margin-bottom: 20px;

  &__title {
    font-size: 2rem;
    margin-bottom: 10px;
    //padding: 10px;
    //margin-bottom: 10px;
    //border-bottom: 3px solid #eee;
    .inner {
      padding: 10px;
    }
    &--attention {
      color: #f00;
    }
    &--small {
      font-size: 1.6rem;
    }
  }
  &___sub-title {
    font-size: 1.8rem;
  }
  &--border {
    border: 1px solid #ccc;
  }
  &__green {
    background-color: #f2fef0;
    padding: 10px;
  }
}

@media screen and (min-width: $small-pc) {
  .bgBox {
    &__green {
      padding: 15px 50px;
    }
  }
}

.solution {
  margin-bottom: 30px;
  &__title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  &__sub-title {
    border-left: 3px solid #0199cb;
    border-bottom: 1px dashed #bbb;
    padding: 10px;
    margin-bottom: 15px;
  }
}

.index-sitemap {
  margin-top: 30px;
  li {
    list-style: none;
  }
  &__title {
    background-color: #eee;
    border-top: 1px solid #59abd1;
    padding: 10px;
    margin-bottom: 5px;
  }
  &__list {
    padding: 10px;
    margin-bottom: 15px;
  }
  &__item {
    margin-bottom: 5px;
  }
  &__link {
    color: #2967c5;
    text-decoration: none;
    &:before {
      content: '\f0da';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      margin-right: 5px;
    }
    &:visited {
      color: #2967c5;
    }
  }
}

@media screen and (min-width: $small-pc) {
  .index-sitemap {
    &__list {
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      flex-basis: 50%;
      margin-bottom: 5px;
    }
  }
}

.note {
  font-size: 1.2rem;
  margin-top: 20px;
  li {
    list-style: none;
  }
  &__text {
    text-indent: -1.2rem;
    margin-left: 1.2rem;
    margin-bottom: 1rem;
    &:before {
      content: '※';
    }
  }
}

.top-price-table {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  scrollbar-base-color: #229bdb;
  scrollbar-arrow-color: #ffffff;
}
.top-price-table {
  overflow-x: scroll;
  scrollbar-arrow-color: #ff0000;
}
.top-price-table::-webkit-scrollbar {
  height: 10px;
} /*バーの太さ*/
.top-price-table::-webkit-scrollbar-track {
  background: #dddddd;
} /*バーの背景色*/
.top-price-table::-webkit-scrollbar-thumb {
  background: #229bdb;
} /*バーの色*/

.table-striped > tbody > tr:nth-of-type(odd) {
  background: #dff6fa;
}
.table > tbody > tr > td,
.table > tbody > tr > th {
  border: 1px solid #ccc;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

.table-striped {
  font-size: 1.4rem;
}
.table-striped a {
  color: #337ab7;
  text-decoration: none;
  &:hover {
    color: #23527c;
    text-decoration: underline;
  }
}
.table-striped th {
  text-align: left;
}
.table-striped th,
.table-striped td {
  white-space: nowrap;
  padding: 8px;
}
.table-striped td {
  text-align: center;
}

.table-striped .btn {
  border: none;
  cursor: pointer;
  outline: none !important;
  text-decoration: none !important;
  transition: 0.3s;
  box-sizing: border-box;
  color: #fff;
}

.table-striped .btn-danger {
  font-weight: normal;
  background-image: -webkit-linear-gradient(top, #d9534f 0, #c12e2a 100%);
  background-image: -o-linear-gradient(top, #d9534f 0, #c12e2a 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#d9534f),
    to(#c12e2a)
  );
  background-image: linear-gradient(to bottom, #d9534f 0, #c12e2a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc12e2a', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  background-repeat: repeat-x;
  border-color: #b92c28;

  text-shadow: 0 -1px 0 rgba(0, 0, 0, 20%);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 15%),
    0 1px 1px rgba(0, 0, 0, 8%);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 15%),
    0 1px 1px rgba(0, 0, 0, 8%);

  &:hover {
    color: #000;
    background: #ffff00;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
