@import "foundation/_mixin.scss";
@import "foundation/_reset.scss";
@import "foundation/_variables.scss";
@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_sidebar.scss";
@import "object/component/_article.scss";
@import "object/component/_back-link.scss";
@import "object/component/_breadcrumb.scss";
@import "object/component/_btn.scss";
@import "object/component/_drawer.scss";
@import "object/component/_font.scss";
@import "object/component/_internal-link.scss";
@import "object/component/_list.scss";
@import "object/component/_nav.scss";
@import "object/component/_next-link.scss";
@import "object/component/_note.scss";
@import "object/component/_page-header.scss";
@import "object/component/_sub-nav.scss";
@import "object/component/_toPC.scss";
@import "object/project/_comp.scss";
@import "object/project/_furnish.scss";
@import "object/project/_medical.scss";
@import "object/project/_price.scss";
@import "object/project/_service.scss";
@import "object/project/_top.scss";
@import "object/project/_utility.scss";
@import "object/utility/_lead.scss";
@import "object/utility/_list.scss";
@import "object/utility/_margin.scss";

body {
}
.container {
  width: 100%;
  //margin: 0 auto;
  line-height: 1.7;
}
.container:after {
  content: '';
  display: table;
  clear: both;
}

.sp {
  display: inline;
}
.pc {
  display: none;
}

@media screen and (min-width: $small-pc) {
  .sp {
    display: none;
  }
  .pc {
    display: inline;
  }

  .container {
    width: 1000px;
    margin: 15px auto 0;
  }
  .main {
    width: 744px;
    float: right;
  }
}

.main {
  line-height: 1.7;
}

@media screen and (max-width: $small-pc) {
  .wrap {
    width: 94%;
    margin: 0 auto;
  }
}

$title-red: #f00;
$title-large: 2.4rem;

.title {
  margin-bottom: 10px;
  &--red {
    color: $title-red;
  }
  &--large {
    font-size: $title-large;
  }
}

.-red {
  color: #f00;
}
.-blue {
  color: #039;
}
.-normal {
  font-weight: normal;
}
.-bold {
  font-weight: bold;
}
.-small {
  font-size: 1.2rem;
}
.-large {
  font-size: 1.8rem;
}

.t-left {
  text-align: left;
}
.t-center {
  text-align: center;
}
.t-right {
  text-align: right;
}

.pd10 {
  padding: 10px;
}

.max-image {
  width: 100%;
  height: auto;
}

@media screen and (min-width: $small-pc) {
  .max-image {
    width: auto;
    height: auto;
  }
}

.list-inside {
  list-style-position: inside;
}
