html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    color: #333;
    //font-family: "Hiragino Kaku Gothic ProN", -apple-system, blinkMacSystemFont, YuGothic-M, YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
    //font-family: "M PLUS 1p";
    //font-family: 'Noto Sans JP';
    //font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;

    font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;;
    -webkit-text-size-adjust: 100%;
    font-feature-settings: "palt";

    font-weight: 400;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    ::i-block-chrome, body {
        font-feature-settings: "pkna";
    }
}
