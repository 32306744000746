.header {
    width: 94%;
    padding-top: 5px;
    margin: 0 auto;
    border-bottom: 1px solid #ccc;
    h1 {
        font-size: 1.3rem;
        color: #06C;
    }
    .header__logo {
        line-height: 1;
        img {
            width: 100%;
        }
    }
    &__agent {
        font-size: 1.3rem;
        color: #555;
        font-weight: bold;
    }
    .pc-nav {
        display: none;
    }

}
.head__utility {
    display: none;
}
@media screen and (max-width: $small-pc) {
    .header__logo {
        margin-bottom: 10px;
    }
}


@media screen and (min-width: $small-pc){
    .header {
        width: 1000px;
        border-bottom: 0;
        &__logo {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            align-items: flex-end;
            img {
                width: auto;
            }
        }
        &__text {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
        }
        .sp-nav {
            display: none;
        }
        .pc-nav {
            display: block;
        }
    }
    .head__utility {
        font-size: 1.3rem;
        display: flex;
        list-style: none;
        justify-content: flex-end;
        margin-bottom: 5px;
        li {
            margin-left: 10px;
        }
        a {
            text-decoration: none;
            color: #06C;
            &:before {
                @include fontAwosome($content:'\f138');
            }
        }
    }
}
