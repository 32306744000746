.nav {
    font-size: 1.5rem;
    line-height: 1;
    //background-image: linear-gradient(90deg, #fdfbfb 0%, #ebedee 100%);
    background-image: linear-gradient(to bottom, #fff 0%, #ddd 38%, #fff 100%);
    border-top: 3px solid #59ABD1;
    border-bottom: 1px solid #ccc;
    li {
        list-style: none;
    }
    &__first-list {
        display: flex;
        justify-content: space-around;
    }
    &__first-item {
        flex-basis: 33.333%;
        &:nth-child(2) {
            border: 1px solid #999;
            border-width: 0 1px;
        }
    }
    &__first-link {
        text-decoration: none;
        font-weight: bold;
        padding: 12px 10px;
        display: block;
        //background-color: #eee;
        text-align: center;
        color: #333;
    }
    &__second-list {
        display: none;
    }
    a {
        &:hover,
        &.current {
            background: rgb(55, 136, 173);
            color: #fff;
        }
    }
}
