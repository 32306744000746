.btn {
    background: #FFF;
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    transition: all .2s;
    -webkit-transition: all 0.1s;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    border-bottom: 5px solid #8d111d;
    text-decoration: none;
    font-weight: 900;

    display: block;
    a {
        display: block;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    &:hover {
        opacity: .7;
    }
    &__description {
        font-weight: normal;
        font-size: 1.4rem;
        line-height: 1.4;
        display: block;
    }
    &--apply {
        background-image: linear-gradient(to bottom, #dc8984 0%, #D62828 32%, #c21b1b 100%);
        padding: 11px 0;
        line-height: 0;
    }


    &--attention {
        background-color: #f00;
        text-align: center;
        //color: #fff;
        padding: 5px 0;
        font-size: 1.2rem;
        //border-top-left-radius: 5px;
        //border-top-right-radius: 5px;
        //font-weight: bold;
        margin: 5px;
        line-height: 1;
        border-radius: 10px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    }
    &--simulation {
        display: block;
        background: #4695BB;
        color: #fff;
        font-size: 1.8rem;
        line-height: 2.7rem;
        font-weight: 900;
        padding-top: 10px;
        border-bottom: 5px solid #2e546b;
    }
    &--price {
        background-image: linear-gradient(#ff5151 0%, #e80000 100%);
        color: #fff;
        margin-bottom: 10px;
        line-height: 1.5;
        padding: 15px;
        span {
            font-size: 2.6rem;
        }
    }
}



@media screen and (max-width: 600px) {
    .apply-btn-wrapper {
        display: flex;
        justify-content: center;
    }
}
