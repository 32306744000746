.to-pc {
    text-align: center;
    a {
        font-size: 1.4rem;
        text-decoration: none;
        color: #333;
        display: inline-block;
        border: 1px solid #ccc;
        margin: 30px auto 0;
        padding: 10px 30px;
        background-color: #eee;
    }
}