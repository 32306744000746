.article {
    &__h1 {
        font-size: 2.2rem;
        line-height: 1.3;
        margin: 10px 0 20px;
        span {
            font-weight: normal;
            font-size: 1.6rem;
        }
    }
    &__h2 {
        background-color: $main-color;
        border-radius: $border-radius;
        color: #fff;
        padding: 10px;
        font-size: 1.8rem;
        margin-bottom: 20px;

    }
    &__h3 {
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
    }
    &__h4 {
    }
    p, ul, ol {
        margin-bottom: 1.5rem;
    }
    section {
        margin-bottom: 50px;

        section {
            margin-bottom: 35px;
        }
    }
}

@media screen and (max-width: $small-pc) {
    .article {
        width: 94%;
        margin: 0 auto;
    }
}

.signature {
    text-align: right;
    margin-bottom: 10px;
    font-size: 1.3rem;
}


h2,
h3 {
    //font-family: "Noto Sans JP Black", "M PLUS 1p";
    //font-weight: 900;
}
