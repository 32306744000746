.sidebar {
    li {
        list-style: none;
    }
    &-btn {
        margin-bottom: 10px;
    }
    &-banner {
        margin-bottom: 30px;
    }
    &-info {
        margin-bottom: 30px;
        &-inner {
            display: flex;

        }
        &__img {
            margin-right: 10px;
        }
        &__text {
            p {
                font-size: 1.2rem;
            }
        }
    }
    &-recommend {
        margin-bottom: 30px;
        li {
            margin-bottom: 10px;
        }
        a {
            text-decoration: none;
            color: #333;
            font-weight: bold;
            text-align: center;
            display: block;
            border-radius: 5px;
            padding: 15px 0;
            border: 1px solid #ccc;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
        }
    }
    &-pr {
        h3 {
            font-size: 1.3rem;
            background: red;
            color: white;
            display: inline-block;
            padding: 5px 10px;
            border-radius: 5px;
            margin-bottom: 10px;
        }
        a {
            display: block;
            text-align: center;
        }
    }
}

@media screen and (min-width: $small-pc) {
    .sidebar {
        float: left;
        width: 230px;

    }

}
