.narita-table {
  tr {
    vertical-align: top;
    td:first-child {
      white-space: nowrap;
    }
  }
}

.desklist-table {
  width: 100%;
  font-size: 1.3rem;
  border-collapse: collapse;
  tr {
    &:first-child {
      background-color: #4398c6;
      color: #fff;
    }
    &:nth-child(2) {
      background-color: #c6d4de;
    }
  }
  th,
  td {
    border: 1px solid #333;
    padding: 5px;
  }
  th {
    &:first-child {
      width: 45%;
    }
    &:nth-child(2) {
      width: 45%;
    }
    &:nth-child(3) {
      width: 10%;
    }
  }
  td {
    &:nth-child(3) {
      text-align: center;
    }
  }
}

.claim-list {
  & > li {
    list-style: none;
  }
  em {
    font-style: normal;
    font-size: 1.7rem;
    font-weight: bold;
  }
  strong {
    font-weight: normal;
    color: red;
  }
  .disc {
    margin-bottom: 0;
    font-size: 1.4rem;
  }
}
.claim-table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin-bottom: 2rem;
  th,
  td {
    border: 1px solid #ccc;
    padding: 10px;
  }
  th {
    width: 25%;
    background: #dae9fd;
    font-weight: normal;
  }
  td {
    width: 75%;
  }
  p {
    margin: 0;
    display: inline;
  }
  hr {
    height: 1px;
    border-top: 1px solid #ccc;
    margin: 3px 0;
  }
}

.hospital-content {
  table {
    width: 100%;
    margin-bottom: 40px;
    th,
    td {
      display: block;
      width: 100%;
    }
    td {
      border-top: 1px solid #ddd;
      padding: 10px;
      &:nth-child(1) {
        font-weight: bold;
        font-size: 1.6rem;
        background: #ddd;
      }
      &:nth-child(3) {
        p {
          display: block;
        }
      }
      &:nth-child(4) {
        p {
          display: block;
        }
      }
      &:nth-child(5) {
        p {
          display: block;
        }
      }
      &:nth-child(6) {
        &::before {
          content: '予約可否：';
        }
      }
      &:nth-child(7) {
        &::before {
          content: '日本語可否：';
        }
      }
      &:nth-child(8) {
        p {
          display: block;
        }
      }
      &:last-child {
        padding-bottom: 15px;
      }
    }
    p {
      margin: 0;
      display: inline;
    }
  }
}
