.breadcrumb {
    display: none;
}

@media screen and (min-width: $small-pc) {
    .breadcrumb {
        font-size: 1.2rem;
        display: flex;
        margin: 10px 0;
        li {
            list-style: none;
            &:after {
                content: ' > ';
                margin: 0 5px;
            }
            &:last-child {
                &:after {
                    content: none;
                }
            }
            a {
                text-decoration: none;
                color: #555;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}