.internal-link {
    //display: none;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    li {
        width: 50%;
        margin: 5px 0;
        a {
            text-decoration: none;
            color: #333;
            &:before {
                @include fontAwosome($content:'\f107');
            }
        }
    }
}

@media screen and (min-width: $small-pc) {
    .internal-link {
        //list-style: none;
        //display: flex;
        font-size: 1.4rem;
        //justify-content: space-around;
        margin: 20px 0;
        li {
            margin-right: 15px;
            flex-basis: 30%;

        }
        a {
            text-decoration: none;
            color: $text-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }

}
